import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import PostContent from "@components/post-content/post-content";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import "../styles/components/documentation/index.scss"

/**
 * Layouts
 */
import Layout from '@layouts/default';

const pluginParams = [
  {
    name: "myAccountUrl",
    default: "Starberry will provide",
    description: "Provide the myaccount URL",
    required: true,
  },
  {
    name: "apiUrl",
    default: "Starberry will provide",
    description: "Provide the API Url",
    required: true,
  },
  {
    name: "cookieDomain",
    default: ".starberry.com",
    description: "Provide public domain name here",
    required: true,
  },
  {
    name: "searchDataAttribute",
    default: "mavalue",
    description: "Where the save search search params present"
  },
  {
    name: "propertyDataAttribute",
    default: "mapropertyid",
    description: "Where the property id present"
  },
  {
    name: "searchItemClass",
    default: "ma-save-search",
    description: "Save search element's class"
  },
  {
    name: "propertyItemClass",
    default: "ma-save-property",
    description: "Save property element's class"
  },
  {
    name: "bookViewingBtnClass",
    default: "ma-book-viewing",
    description: "Book a viewing button class that will use to trigger the script"
  },
  {
    name: "bookViewingBtnDataAttribute",
    default: "mapropertyid",
    description: "Where the property id present"
  },
  {
    name: "actionTarget",
    default: ".ma-sb-myacc",
    description: "Common class to trigger action for all the above such as Save search, Save property, Book a viewing"
  },
  {
    name: "activeClass",
    default: "saved",
    description: "Class to add to identify the saved item (Save search/ Property)"
  },
  {
    name: "autoFillFields",
    default: `{
      name: 'name',
      email: 'email',
      phone: 'phone'
    }`,
    description: "List the form fields name that auto fill if user session exits. Keep the key, just chnages value based on your from input names"
  }
]


class CookiePage extends React.Component {

  render() {

    return (
      <Layout>

        <SEO title="TPW" description="Test" />

        <Container className="documentation">
          <div className="post-content">
              <div className="post-content__inner">
                  <div>
                    <br />
                    <br />
                    <h4>My Account - Third Party Website Integration</h4>

                    <a href="#overview"><h5 id="overview"># Overview</h5></a>
                    <p>The My Account Enterprise is a product from the Starberry stables that will allow estate agents to allow their clients to seamlessly move through the sales funnel without having to spend hours doing the tedious tasks of updating the CRM system whenever an activity occurs.</p>
                    <p>The My Account Enterprise product is available to clients who are on the Reapit (Paas) CRM on AWS. The following elements can be customised by the client</p>
                    <ul>
                      <li>Colour</li>
                      <li>Logo</li>
                      <li>Fonts</li>
                    </ul>
                    <br />
                    <p>This document  is only for those who already have a  website and would like to integrate Starberry My Account with it.</p>

                    <h5 class="step">Step 1: Add the My Account website integration script</h5>
                    <p>My Account integration script handles the Save search, Save property, Auto fill forms & handle Book a viewing widget.</p>
                    <p>Include the following script on the &#60;head&#62; section of the website</p>

                    <p><strong>&#60;MyaccountUrl&#62;/widgets/core/mascript.min.js</strong> <br />
                    Note: &#60;MyaccountUrl&#62; is project specific and will be provided to you by Starberry</p>

                    <LiveProvider
                      mountStylesheet={false}
                      code={`<script src="https://myaccount-demo-dev.starberry.com/widgets/core/mascript.min.js" defer=""></script>"`}
                      disabled={true}
                    >
                      <LiveEditor />
                    </LiveProvider>
                    <br />
                    <p>Note: Replace `https://myaccount-demo-dev.starberry.com` with actual url. This will be provided to you by Starberry</p>
                    <h5 class="step">Step 2: Call myAccountUrl function with required parameters</h5>
                    <p>After including the above script into the page, myAccountUrl function can be accessible in dom. Call the function with the necessary parameters</p>

                    <LiveProvider
                      mountStylesheet={false}
                      code={`myAccount({
  myAccountUrl: "https://myaccount-demo-dev.starberry.com", // Starberry will provide
  apiUrl: "https://admin-demo-dev.starberry.com" // Starberry will provide
  cookieDomain: ".starberry.com" // Public domain name here
});`}
                      disabled={true}
                    >
                      {/* <LivePreview /> */}
                      <LiveEditor />
                      {/* <LiveError /> */}
                    </LiveProvider>
                    <br />
                    <p>You can overwrite all default params when you need. Check available parameters <a href="#params">here</a>.</p>
                    <h5 class="step">Step 3: Start adding the feature</h5>
                    <a href="#save-search"><h5 id="save-search"># Save Search</h5></a>
                    <p>In cases where the user is on the lookout for a property to buy, any searches saved on the website will be displayed here along with the saved criteria.</p>

                    <p>Please provide the applicable search params in the following order, make sure all data is sent in lowercase.</p>

                    <LiveProvider
                    mountStylesheet={false}
                      code={`{
	"department": "residential/commercial",
	"search_type": "sales/lettings",
	"areas": "area name if exists or pass default location",
	"bedroom": "no of beds",
	"minPrice":  "Min Price",
	"maxPrice":  "Max Price",
	"building": "building type",
	"feature": "features if any"
}`}
                      disabled={true}
                    >
                      {/* <LivePreview /> */}
                      <LiveEditor />
                      <LiveError />
                    </LiveProvider>
                    <br />
                    <p>And make them as JSON.stringify(), example below</p>
                    {/* <p>Add the following attributes to the parent of Save Search actionable item(icon or text link)</p> */}
                    {/* <h6>Live Demo <span>( Click below )</span></h6> */}
                    <LiveProvider
                    mountStylesheet={false}
code={`<div class="icon-item">
  <span data-mavalue='{"search_type":"sales","areas":"west yorkshire"}' class="ma-sb-myacc ma-save-search">
    <span class="save-text">Save Search</span>
    <span class="saved-text">Saved</span>
  </span>
</div>`}
                      disabled={true}
                    >
                      <LiveEditor />
                      <LiveError />
                      <br />
                      <h6>Live Demo <span>( Click below )</span></h6>
                      <div className="live-preview">
                        <LivePreview />
                      </div>
                    </LiveProvider>
                    <p>When a search criteria is Saved, it will automatically add the “Saved” class in the “Save Search” element. This can be overridden.</p>
                    <a href="#save-property"><h5 id="save-property"># Save Property / Wishlist</h5></a>
                    <p>In cases where the user is on the lookout for a property to buy, any properties that are shortlisted by the user for enquiry will be available here. Include element like below.</p>

                    {/* <h6>Live Demo <span>( Click below )</span></h6> */}

                    <LiveProvider
                    mountStylesheet={false}
code={`<div data-mapropertyid="MKT220287" class="ma-sb-myacc ma-save-property">
  <span class="save-text">Save Property</span>
  <span class="saved-text">Saved</span>
</div>`}
                      disabled={true}
                    >
                      <LiveEditor />
                      <LiveError />
                      <br />
                      <h6>Live Demo <span>( Click below )</span></h6>
                      <div className="live-preview">
                        <LivePreview />
                      </div>
                    </LiveProvider>
                    <p>When a property is Saved, it will automatically add the “Saved” class in the wishlist element. This can be overridden.</p>

                    <a href="#book-a-viewing"><h5 id="book-a-viewing"># Book a Viewing Widget</h5></a>
                    <p>This widget captures basic user info and provides the real-time availability slots (either property calendar or negotiator calendar) for the user to request a viewing. Include <strong>class="ma-book-viewing ma-sb-myacc"</strong> and <strong>data-mapropertyid="property CRM id here..."</strong> in your trigger button like below.</p>

                    
                    <LiveProvider
                    mountStylesheet={false}
code={`<button class="btn btn-primary ma-book-viewing ma-sb-myacc" data-mapropertyid="MKT220232">
  Book a Viewing
</button>`}
                      disabled={true}
                    >
                      <LiveEditor />
                      <LiveError />
                      <br />
                      <h6>Live Demo <span>( Click below )</span></h6>
                      <div className="live-preview">
                        <LivePreview />
                      </div>
                    </LiveProvider>
                    <br />
                    <a href="#params"><h5 id="params"># Parameters</h5></a>
                    <table striped class="bg-white mb-0 table table-striped table-bordered">
                      <thead>
                        <tr>
                            <th>Name</th>
                            <th>Default</th>
                            <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pluginParams.map((item) => {
                          return(
                            <tr class="prop-table-row">
                              <td class="text-monospace">
                                <div>
                                  {item.name}
                                  {item?.required && (
                                    <span><br />*required</span>
                                  )}
                                </div>
                              </td>
                              <td>{item.default}</td>
                              <td>
                                <div class="">
                                    {item.description}
                                </div>
                              </td>
                            </tr>
                          )}
                        )}
                      </tbody>
                    </table>
                  </div>
              </div>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default CookiePage
